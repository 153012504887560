import { Component, Vue, Watch } from 'vue-property-decorator';
import MiSettings from '@/components/MiSettings/MiSettings.vue';

@Component<Configuration>({
  components: {
    MiSettings,
  },
})
export default class Configuration extends Vue {
  public $pageTitle = 'Instellingen';

  protected menu = false;

  protected visibleSettings: tcmgSetting[] = [
    {
      key: 'bezwaar_email_notification_address',
      value: '',
      label: 'Algemene postvak Bezwaar',
      description: 'Naar welk algemene e-mailadres kunnen alle notificaties betreft bezwaren verstuurd worden?',
      type: 'text-field',
      visible: true,
    },
    {
      key: 'bezwaar_email_notifications',
      value: '',
      label: 'Notificaties bezwaar',
      description: 'Met deze optie wordt er voor alle aangemaakte bezwaren een notificatie gestuurd naar het opgegeven e-mailadres',
      type: 'switch',
      visible: true,
    },
    {
      key: 'expert_archive_visibility',
      value: '',
      label: 'Archiefknop tonen',
      description: 'Deskundigen toegang geven tot opgeleverde dossiers',
      type: 'switch',
      visible: ! this.$store.state.isServiceOrganization,
    },
  ];

  protected date: string | null = null;

  protected requested_planning_notifiable = false;

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    //
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          {
            name: 'Instellingen',
            path: '/instellingen',
          },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface tcmgSetting {
  key: string;
  type: string;
  value: any;
  label: string;
  description?: string;
  menu?: boolean;
  visible?: boolean;
}
